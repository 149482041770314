<template>
  <v-container  v-if="template" class="pa-4">
    <header>
      <h5>{{ template.title }}</h5>
      <span v-if="template.description" style="color: gray; margin-left: .5rem;">{{ template.description }}</span>
    </header>
    <hr class="my-6 grey lighten-5">
    <v-form lazy-validation v-model="valid" ref="form">
      <ol>
        <li v-for="question in template.questions" :key="question.id">
          <v-row>
            <question-component v-if="question.type === 1" :question="question" />
            <question-items-component v-else-if="question.type === 2" :question="question" />
          </v-row>
        </li>
      </ol>
    </v-form>
    <v-row class="mobile-direction-column">
      <span class="custom-text" style="font-size: .75rem; width: 100%"><b style="font-size: 1rem">*</b> {{ template.disclaimer }}</span>
      <v-btn class="custom-send-button ma-2" depressed color="#25A256" style="color: white" :disabled="!valid" @click="submitSurvey">trimite</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import QuestionComponent from './QuestionComponent.vue';
import QuestionItemsComponent from './QuestionItemsComponent.vue';
import { mapGetters } from 'vuex';
  export default {
    name: 'SurveyComponent',
    components: { 
      [QuestionComponent.name]: QuestionComponent,
      [QuestionItemsComponent.name]: QuestionItemsComponent,
    },
    data() {
      return {
        valid: true,
      }
    },
    computed: {
      ...mapGetters({
        template: 'getTemplate',
      }),
    },
    methods: {
      submitSurvey() {
        const isValid = this.$refs.form.validate();
        if(isValid) {
          this.$store.dispatch('SubmitSurvey');
        }
      },
    },
  }
</script>
