<template>
  <div class="container-403 d-flex justify-center align-center">
    <div class="subcontainer-403 d-flex flex-row justify-center align-center">
      <svg
        viewBox="0 0 837 1245"
      >
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          sketch:type="MSPage"
        >
          <path
            d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z"
            id="Polygon-1"
            stroke="#004818"
            stroke-width="4"
            sketch:type="MSShapeGroup"
          ></path>
          <path
            d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z"
            id="Polygon-2"
            stroke="#004818"
            stroke-width="4"
            sketch:type="MSShapeGroup"
          ></path>
          <path
            d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z"
            id="Polygon-3"
            stroke="#f0ec93"
            stroke-width="4"
            sketch:type="MSShapeGroup"
          ></path>
          <path
            d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z"
            id="Polygon-4"
            stroke="#f0ec93"
            stroke-width="4"
            sketch:type="MSShapeGroup"
          ></path>
          <path
            d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z"
            id="Polygon-5"
            stroke="#f0ec93"
            stroke-width="4"
            sketch:type="MSShapeGroup"
          ></path>
          <path
            d="M581,901 L683,961.025276 L683,1079.21169 L581,1137 L479,1079.21169 L479,961.025276 L581,901 Z"
            id="Polygon-6"
            stroke="#004818"
            stroke-width="4"
            sketch:type="MSShapeGroup"
          ></path>
        </g>
      </svg>
      <div class="message-box">
        <div class="d-flex justify-center pb-6">
          <img class="logo-403"  src="/img/logo.svg" />
        </div>
        <p v-if="setRedirectTimeout()">Ai completat deja acest chestionar.<br>Iți mulțumim, Echipa CEC Bank!<br><span style="font-size: .85rem">Pentru alte produse și servicii te redirecționăm pe <a href="https://www.cec.ro">www.cec.ro</a></span></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageError403',
  methods: {
    setRedirectTimeout() {
      setTimeout(() => {
        this.$location.assign('https://www.cec.ro');
      }, 5000);
      return true;
    },
  }
}
</script>


<style scoped>
.buttons-con .action-link-wrap {
  margin-top: 40px;
}
.message-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.2rem;
  margin-left: 4rem;
  max-width: 22rem;
  text-align: center;
}

.logo-403 {
  height: 50px;
}

svg {
  width: 25rem;
}

.subcontainer-403 {
  min-width: 60vw;
  min-height: 100vh;
  column-gap: 3vw;
}

#Polygon-1,
#Polygon-2,
#Polygon-3,
#Polygon-4,
#Polygon-4,
#Polygon-5,
#Polygon-6 {
  animation: float 1s infinite ease-in-out alternate;
}
#Polygon-2 {
  animation-delay: 0.5s;
}
#Polygon-3 {
  animation-delay: 1s;
}
#Polygon-4 {
  animation-delay: 1.5s;
}
#Polygon-5 {
  animation-delay: 2s;
}
#Polygon-6 {
  animation-delay: 2.5s;
}

@keyframes float {
  100% {
    transform: translateY(25px);
  }
}

@media (max-width: 450px) {
  .subcontainer-403 {
    height: 50px;
    flex-direction: column!important;
  }
  .message-box {
    justify-content: start!important;
    transform: scale(.75);
    margin-left: 0;
  }
  svg {
    width: 17rem;
  }
}
@media (min-width: 1441px) {
  .message-box {
    margin-left: 5rem;
    transform: scale(1.1);
  }
}
@media (min-width: 2001px) {
  .message-box {
    margin-left: 5rem;
    transform: scale(1.2);
  }
}
</style>
