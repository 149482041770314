import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    loading: true,
    error: null,
    data: {}
  },
  getters: {
    getData(state) {
      return state.data
    },
    getSurvey(state) {
      return state.data.survey;
    },
    getTemplate(state) {
      return state.data.template;
    },
    isLoading(state) {
      return state.loading;
    },
    isError(state) {
      return state.error;
    }
  },
  mutations: {
    setSurvey (state, data) {
      Vue.set(state, 'data', data);
    },
    setToken(state, token) {
      Vue.set(state, 'token', token);
    },
    setLoading(state, loading) {
      Vue.set(state, 'loading', loading);
    },
    setError(state, error) {
      Vue.set(state, 'error', error);
    }
  },
  actions: {
    async FetchSurvey ({ state, commit }) {
      if(!state.token) {
        commit('setError', { message: "Not found", code: 404});
        return;
      }
        await fetch(`${process.env.VUE_APP_CLIENT_ENV}/SurveyRetrieval?token=${state.token}`)
        .then(response => response.json())
        .then(data => {
            if(data.status === 200) {
                let clone = data.data;
                clone.template.questions = data.data.template.questions.map(question => {
                    question.answer = null;
                    return question
                });
                commit('setLoading', false);
                commit('setSurvey', clone)
            }else {
                commit('setLoading', false);
                commit('setError', { message: data.message, code: data.status });
            }
        })
        .catch(error => {
            commit('setLoading', false);
            commit('setError', { message: error.message, code: error.status });
        })
    },
    async SubmitSurvey ({ state, commit }) {
        commit('setLoading', true);
        let dataClone = {...state.data}
        
        for(let i=0; i < dataClone.length; i++) {
          dataClone[i] = dataClone[i].replace(/</g, "&lt;").replace(/>/g, "&gt;");
         }

        await fetch(`${process.env.VUE_APP_CLIENT_ENV}/SurveySubmission`, {
            method: 'POST',
            body: JSON.stringify(
                {
                    token: state.token,
                    data: dataClone
                }
                ),
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(response => response.json())
        .then(data => {
            if(data.status === 200) {
              commit('setLoading', false);
              commit('setError', { message: data.message, code: data.status });
            }else {
              commit('setLoading', false);
              commit('setError', { message: data.message, code: data.status });
              setTimeout(() => {
                location.reload();
              }, 3000);
            }
        })
        .catch(error => {
          commit('setError', { message: error.message, code: error.status });
        })
    }
}
})
