<template>
  <v-app id="app">
    <div v-if="!isLoading && !error" class="survey-container">
      <div>
        <div class="pb-16 pt-4 d-flex justify-center">
          <img class="logo"  src="/img/logo.svg" />
        </div>
        <v-container fluid class="main-body pa-0 mb-7">
          <survey-component />
        </v-container>
      </div>
      <v-footer color="white" padless>
        <v-col class="text-center white--text pa-2" cols="12">
          <span style="font-size: 1rem; color: black">©CEC Bank {{ new Date().getFullYear() }}</span>
        </v-col>
      </v-footer>
    </div>
    <v-container v-else-if="isLoading && !error" class="loading_module pa-0">
      <v-progress-circular size="100" indeterminate color="red darken-2" ></v-progress-circular>
    </v-container>
    <v-container class="pa-0" v-else-if="error && error.code === 403">
      <PageError403 />
    </v-container>
    <v-container class="pa-0" v-else-if="error && error.code === 404">
      <PageError404 />
    </v-container>
    <v-container class="pa-0" v-else-if="error && customCodes.includes(error.code)">
      <BEMessagesPage :error="error" />
    </v-container>
    <v-container class="d-flex justify-center align-center flex-column pa-0" style="flex-grow: 1" v-else-if="error && error.code">
      <v-icon style="font-size: 7rem" color="#004818">mdi-check-circle</v-icon>
      <span v-if="setRedirectTimeout()" style="text-align: center">Răspunsul tău a fost înregistrat.<br>Mulțumim, Echipa CEC Bank!<br>Pentru alte produse si servicii te redirectionam pe <a href="https://www.cec.ro">www.cec.ro</a></span>
    </v-container>
    <v-container class="d-flex justify-center align-center flex-column pa-0" style="flex-grow: 1" v-else>
      <v-icon style="font-size: 7rem" color="red darken-5">mdi-close-circle</v-icon>
      <span style="text-transform: uppercase">A apărut o eroare</span>
    </v-container>
  </v-app>
</template>
<script>
import SurveyComponent from './components/SurveyComponent.vue';
import BEMessagesPage from './components/BEMessagesPage.vue';
import PageError403 from './components/PageError403.vue';
import PageError404 from './components/PageError404.vue';
import { mapGetters } from 'vuex';
export default {
    name: 'App',
    components: {
        [SurveyComponent.name]: SurveyComponent,
        [BEMessagesPage.name]: BEMessagesPage,
        [PageError403.name]: PageError403,
        [PageError404.name]: PageError404
    },
    data() {
        return {
            customCodes: [429],
        };
    },
    computed: {
        ...mapGetters({
            data: 'getData',
            isLoading: 'isLoading',
            error: 'isError',
            template: 'getTemplate',
        }),
    },
    methods: {
        setRedirectTimeout() {
          setTimeout(() => {
            this.$location.assign('https://www.cec.ro');
          }, 5000);
          return true;
        },
    },
    async created() {
      await this.$store.commit('setToken', window.location.href.split('/').pop());
      await this.$store.dispatch('FetchSurvey');
    },
}
</script>
<style>
  html, body {
    min-height: 100% !important;
    height: 100%;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
  }
  p {
    text-align: left;
    font: normal normal normal 14x/22px Montserrat;
    letter-spacing: 0px;
    color: #646464;
    opacity: 1;
  }
  h4 {
    text-align: left;
    font: normal normal 600 30px/39px Montserrat;
    letter-spacing: 0px;
    color: #646464;
    opacity: 1;
  }
  h5 {
    text-align: left;
    font: normal normal 700 18px/26px Montserrat;
    letter-spacing: 0px;
    color: #646464;
    opacity: 1;
  }
  ::marker {
    text-align: left;
    font: normal normal normal 18px/26px Montserrat;
    letter-spacing: 0px;
    color: #646464;
    opacity: 1;
  }
  ul {
    list-style-type: disc;
  }
  ul li::marker {
    font-size: 1.3em;
    color: #646464;
  }
  .v-application {
    font-family: 'Montserrat', sans-serif;
  }
  .loading_module {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .error_module {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom-send-button {
    font: normal normal normal 20px/35px Montserrat;
    letter-spacing: 0.6px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
    min-width: 180px;
    padding: 0px 22px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    margin-bottom: 8px;
    text-align: center;
    font-weight: 700;
    line-height: 1;
    display: inline-block;
    border-radius: 22px;
    transition: all 2ms;
  }
  .custom-send-button:hover {
    background:#008835!important;
  }
  .bg-cec {
    background-color: #004818;
  }
  .main-body {
    max-width: 70vw;
    margin-top: -3.5rem;
    background: white;
    border-radius: .25rem;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
  }
  .logo {
    height: 40px;
  }

  .no-recommend-text {
    position: absolute;
    font-size: .65rem;
    bottom: -.65rem;
    left: 0;
    padding-left: .5rem;
  }

  .recommend-text {
    position: absolute;
    font-size: .65rem;
    bottom: -.65rem;
    left: -.4rem;
  }

  .no-recommend-star-text {
    position: absolute;
    font-size: .65rem;
    bottom: -.25rem;
    left: 0;
    padding-left: .75rem;
  }

  .recommend-star-text {
    position: absolute;
    font-size: .65rem;
    bottom: -.25rem;
    right: .75rem;
    padding-left: .3rem;
  }

  .survey-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .custom-text {
    padding: 8px;
    padding-left: 1.5rem!important;
  }
  .v-text-field__details {
    padding: 0 0.25rem!important;
  }
  .v-input__slot fieldset {
    border-width: 2px;
  }
  .v-counter {
    font-weight: 700;
  }
  .remove-padding-mobile {
    padding-left: 12px!important;
  }
  .mobile-direction-column {
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: flex-end;
  }

  
  @media only screen and (max-width: 375px) {
    ol {
      padding-left: 10px!important;
    }
    .v-input-rating-custom {
      transform: translate(-51%, -50%)!important;
    }
  }
  @media only screen and (max-width: 425px) {
    .mobile-direction-column {
      flex-direction: column;
      justify-content: center;
    }
    .mobile-direction-column {
      justify-content: center!important;
      align-items: center!important;
    }
    .custom-text {
      padding-left: 1rem!important;
    }
    /* .v-input-rating-custom {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-53%, -50%);
    } */
    /* .remove-padding-mobile .v-input__slot {
      min-height: 40px;
    } */
  }
  @media only screen and (max-width: 600px) {
    .v-input-rating-custom {
      /* margin-top: 1rem; */
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-53%, -50%);
    }
    .icon-number-rating {
      font-size: .85rem;
      width: 1.55rem;
      height: 2.5rem;
      margin: 0!important;
      margin-right: .35rem!important;
    }
    .remove-padding-mobile {
      padding-left: 0!important;
    }
    .recommend-text {
      left: 0;
      padding-left: .45rem;
      bottom: -.65rem;
    }
    .no-recommend-text {
      top: -.65rem;
      height: 1rem;
    }
    .remove-padding-mobile .v-input__slot {
      min-height: 40px;
    }
  }
  @media only screen and (max-width: 374px) {
    .icon-number-rating {
      font-size: .75rem;
      width: 1.5rem;
      height: 2.5rem;
      margin: 0!important;
      margin-right: .25rem!important;
    }
  }
  @media only screen and (max-width: 961px) {
    .mobile-padding {
      padding-left: 0!important;
    }
    .main-body {
      max-width: 90vw;
    }
    .mobile-padding-4 {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 1441px) {
    body {
      font-size: 125%;
    }
    .logo {
      height: 50px;
    }
  }
</style>
