<template>
    <v-col>
      <p>{{ question.question }}</p>
      <answer-type-component :item="question" :question="question" :answerType="question.answerType" />
    </v-col>
</template>

<script>
import AnswerTypeComponent from './AnswerTypeComponent.vue';
import { mapGetters } from 'vuex';
  export default {
  components: { AnswerTypeComponent },
    name: 'QuestionComponent',
    props: {
      question: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapGetters({
        data: 'getData',
      }),
    },
    mounted() {
    },
  }
</script>
