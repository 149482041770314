<template>
    <v-container class="pa-0">
        <v-row>
            <v-col v-if="answerType.id === 1" class="pl-4 py-3">
                <v-textarea
                    :value="item.answer"
                    @input="$set(item, 'answer', $event)"
                    :rules="textareaRules"
                    :counter="textareaMaxChars"
                    auto-grow
                    outlined
                    rows="5"
                    row-height="15"
                    name="input-7-4"
                    label="Răspuns"
                    style="max-width: 95%;"
                ></v-textarea>
            </v-col>
            <v-col v-else-if="answerType.maxScore === 10" class="pa-0 pb-3 remove-padding-mobile">
                <v-input :value="item.answer" :rules="ratingNumberRules">
                    <v-rating
                        :value="item.answer === null ? item.answer : item.answer + 1"
                        @input="$set(item, 'answer', $event - 1)"
                        :length="answerType.maxScore + 1"
                        large
                        class="v-input-rating-custom"
                    >
                        <template v-slot:item="props">
                            <span style="position: relative;display: inline-block">
                                <span
                                    class="icon-number-rating"
                                    :style="`border-color: ${ getColor10(props, item.answer) };
                                            background: ${ getColor10(props, item.answer) };
                                            color: ${ getNumberColor(props, item.answer) }`"
                                    @click="props.click"
                                >{{props.index}}</span>
                                <!-- <span class="no-recommend-text" v-if="props.index === 0">Cu siguranță nu aș recomanda </span>
                                <span class="recommend-text" v-else-if="props.index === 8">Cu siguranță voi recomanda  </span> -->
                            </span>
                        </template>
                    </v-rating>
                </v-input>
            </v-col>
            <v-col v-else-if="answerType.maxScore == 5 && !item.type" class="pa-0 pl-1 pb-3">
                <v-input :value="item.answer" :rules="ratingRules">
                    <v-rating
                        :value="item.answer"
                        @input="$set(item, 'answer', $event)"
                        :length="answerType.maxScore"
                        background-color="grey lighten-1"
                        large
                        class="v-input-rating"
                    >
                        <template v-slot:item="props">
                            <span style="position: relative;display: inline-block">
                                <v-icon
                                    large
                                    :color="getColor5(props, item.answer)"
                                    v-text=" props.isFilled ? `mdi-star` : `mdi-star-outline`"
                                    @click="props.click"
                                ></v-icon>
                                <span class="no-recommend-star-text" v-if="props.index === 0">Foarte nemulțumit</span>
                                <span class="recommend-star-text" v-else-if="props.index === 4">Foarte mulțumit</span>
                            </span>
                        </template>
                    </v-rating>
                </v-input>
            </v-col>
            <v-col v-else-if="answerType.maxScore === 5" class="pa-0 pl-1 pb-3">
                <v-input :value="item.answer" :rules="ratingRules">
                    <v-rating
                        :value="item.answer"
                        @input="$set(item, 'answer', $event)"
                        :length="answerType.maxScore"
                        :color="item.answer > answerType.maxScore / 1.2 ? '#004818' : (item.answer < answerType.maxScore / 2 ? 'red lighten-2' : '#f0ec93')"
                        background-color="grey lighten-1"
                        large
                        class="v-input-rating"
                    >
                        <template v-slot:item="props">
                            <span style="position: relative;display: inline-block">
                                <v-icon
                                    large
                                    :color="getColor5(props, item.answer)"
                                    v-text=" props.isFilled ? `mdi-star` : `mdi-star-outline`"
                                    @click="props.click"
                                ></v-icon>
                                <span class="no-recommend-star-text" v-if="props.index === 0">Foarte greu</span>
                                <span class="recommend-star-text" v-else-if="props.index === 4">Foarte ușor</span>
                            </span>
                        </template>
                    </v-rating>
                </v-input>
            </v-col>
            <v-col v-else-if="answerType.maxScore !== 0" class="pa-0 pl-1 pb-3">
                <v-input :value="item.answer" :rules="ratingRules">
                    <v-rating
                        :value="item.answer"
                        @input="$set(item, 'answer', $event)"
                        :length="answerType.maxScore"
                        background-color="grey lighten-1"
                        large
                        class="v-input-rating"
                    >
                        <template v-slot:item="props">
                            <span style="position: relative;display: inline-block">
                                <v-icon
                                    large
                                    :color="getColor5(props, item.answer)"
                                    v-text=" props.isFilled ? `mdi-star` : `mdi-star-outline`"
                                    @click="props.click"
                                ></v-icon>
                            </span>
                        </template>
                    </v-rating>
                </v-input>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'AnswerTypeComponent',
    props: {
        answerType: {
            type: Object,
            required: true,
        },
        item: {
            type: Object
        },
    },
    data() {
        return {
            textareaMaxChars: 500,
            textareaRules: [
                v => !!v || 'Câmpul este obligatoriu',
                v => v?.length <= this.textareaMaxChars || `Maximum ${ this.textareaMaxChars } caractere`
            ],
            ratingRules: [
                v => !!v || 'Câmpul este obligatoriu',
                v => (v >= 0 && v <= this.answerType.maxScore + 1) || 'Valoarea trebuie să fie mai mare sau egal cu 0 si mică sau egală cu ' + this.answerType.maxScore,
            ],
            ratingNumberRules: [
                v => (v !== null) || 'Câmpul este obligatoriu',
                v => (v >= 0 && v <= this.answerType.maxScore + 1) || 'Valoarea trebuie să fie mai mare sau egal cu 0 si mică sau egală cu ' + this.answerType.maxScore,
            ],
        };
    },
    methods: {
        getColor5(props, answer) {
            if(!props.isFilled ) {
                return '';
            }
            if(answer >= 1 && answer <= 3) {
                return '#F0ED8D'
            } else if(answer >= 4) {
                return '#1BA359'
            }
        },
        getNumberColor(props, answer) {
            if(!props.isFilled) {
                return 'gray';
            }
            if(answer >= 0 && answer <= 8) {
                return 'black';
            } else if(answer >= 9) {
                return 'white';
            }
        },
        getColor10(props, answer) {
            if(!props.isFilled) {
                return '';
            }
            if(answer >= 0 && answer <= 8) {
                return '#F0ED8D'
            } else if(answer >= 9) {
                return '#1BA359'
            }
        }
    },
    computed: {
        ...mapGetters({
            data: 'getData'
        }),
    }
}
</script>

<style>
    .icon-number-rating {
        padding: .35rem!important;
        margin: .45rem!important;
        border-radius: .3rem!important;
        width: 2.25rem;
        height: 2.25rem;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        border: 2px solid #757575;
    }
    .icon-number-rating::before {
        border-radius: 5%;
    }
    .v-input-rating-custom {
      font: normal normal normal 18px/26px Montserrat;
      letter-spacing: 0px;
      color: #646464;
      opacity: 1;
    }
</style>