<template>
  <v-col v-if="question" class="mobile-padding">
    <v-container>
      <v-row>
        <p class="mb-4 mobile-padding-4">{{question.question}}</p>
      </v-row>
      <ul class="mobile-padding">
        <li v-for="questionItem in question.questionItems" :key="questionItem.id">
          <v-row>
            <v-col>
              <p>{{ questionItem.content }}</p>
              <answer-type-component :item="questionItem" :answerType="question.answerType" />
            </v-col>
          </v-row>
        </li>
      </ul>
    </v-container>
  </v-col>
</template>

<script>
  import QuestionComponent from './QuestionComponent.vue';
  import AnswerTypeComponent from './AnswerTypeComponent.vue';
  import { mapGetters } from 'vuex';
  export default {
    name: 'QuestionItemsComponent',
    components: { 
      [QuestionComponent.name]: QuestionComponent,
      [AnswerTypeComponent.name]: AnswerTypeComponent,
    },
    props: {
      question: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapGetters({
        data: 'getData',
      }),
    },
  }
</script>
